<template>
  <v-card height="80.5vh" :dark="$dark.get()">
    <v-card-text>
      <v-row>
        <v-col class="order-select__product_wrapper">
          <order-category :category="category" :input="input"></order-category>
          <order-product-select
            :products="products"
            :input="input"
            @new_item="$emit('new_item', $event)"
          ></order-product-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import OrderCategory from "./OrderCategory";
import OrderProductSelect from "./OrderProductSelect";
export default {
  components: { OrderCategory, OrderProductSelect },
  props: {
    category: {
      type: Array,
      required: true,
    },
    products: {
      type: [Array, Object],
      required: true,
    },
    input: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.order-select__product_wrapper {
  overflow-y: auto;
}
</style>
