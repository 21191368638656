<template>
  <div>
    <v-simple-table class="order-product-select__wrapper" fixed-header>
      <thead>
        <tr>
          <th>Фото</th>
          <th>Название</th>
          <th>Цена</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in products.content" :key="item.id">
          <td>
            <v-img
              v-if="item.images.length"
              height="50"
              width="50"
              :src="item.images[0]"
            ></v-img>
          </td>
          <td>{{ item.name }}</td>
          <td>
            <ul>
              <li
                v-for="provider in item.pricesList"
                :key="provider.providerId + provider.departmentId"
              >
                {{ provider.price }}
                <v-btn
                  icon
                  color="primary"
                  @click="
                    $emit('new_item', {
                      product: item,
                      providerId: provider.providerId,
                      price: provider.price,
                    })
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      v-if="products.totalPages >= 1"
      :value="products.number + 1"
      :dark="!$dark.get()"
      light
      total-visible="7"
      :length="products.totalPages"
      @input="input.page = $event - 1"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  props: {
    products: {
      type: [Array, Object],
      required: true,
    },
    input: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.order-product-select__wrapper {
  height: 65vh;
  overflow: auto;
}
</style>
