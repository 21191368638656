<template>
  <v-autocomplete
    v-model="catId"
    :items="category"
    item-text="name"
    item-value="id"
    clearable
    no-data-text="Поиск по категории"
    class="mt-3"
    label="Поиск по категории"
    @change="setCategory()"
    @keydown.enter="setCategory()"
  />
</template>
<script>
export default {
  props: {
    category: {
      type: Array,
      required: true,
    },
    input: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      catId: 0,
    };
  },
  mounted() {
    this.catId = this.input.categoryId;
  },
  methods: {
    setCategory() {
      if (this.catId === null) {
        return;
      }
      this.input.categoryId = this.catId;
      this.input.page = 0;
      this.$emit("close-search");
    },
  },
};
</script>
<style lang="scss" scoped>
.category-item {
  font-size: 0.8225rem;
  font-weight: 500;
  line-height: 1rem;
  white-space: normal;
  cursor: pointer;
}
</style>
