<template>
  <v-card :dark="$dark.get()" class="pa-2" style="border-radius: 0px" flat>
    <v-select
      v-model="input.paymentTypeId"
      :items="PAYMENT.allPayment"
      item-text="name"
      item-value="id"
      label="Оплата"
    ></v-select>
    <v-row>
      <v-col>
        <v-menu
          v-model="showTimePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          min-height="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="input.deliveryTime"
              label="Время доставки"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker v-model="delivery.time" format="24hr"> </v-time-picker>
          <v-date-picker
            v-model="delivery.date"
            :min="new Date().toISOString()"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showTimePicker = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="changeTime()"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-text-field v-model="input.promocode" label="Промокод"></v-text-field>
    <v-textarea v-model="input.comment" label="Комментарий"></v-textarea>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTimePicker: false,
      delivery: {
        time: null,
        date: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      PAYMENT: "Payment/STATE",
    }),
  },
  mounted() {
    if (this.input.deliveryTime) {
      this.delivery.date = this.input.deliveryTime.slice(0, 10);
      this.delivery.time = this.input.deliveryTime.slice(11, 16);
    }
  },
  methods: {
    changeTime() {
      if (this.delivery.date !== null && this.delivery.time !== null) {
        this.input.deliveryTime =
          this.delivery.date + " " + this.delivery.time + ":00";
        this.showTimePicker = false;
      }
    },
  },
};
</script>
