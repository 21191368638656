<template>
  <v-card :dark="$dark.get()" class="order-options__wrapper">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="10" lg="9">
          <v-tabs v-model="tab" :dark="$dark.get()" class="mb-2">
            <v-tab> Продукты </v-tab>
            <v-tab> Клиент </v-tab>
            <v-tab> Детали </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" sm="2" lg="3">
          <v-btn :light="$dark.get()" class="mt-2 ml-2 text-lowercase">
            {{ productPrices + " тг" }}
          </v-btn>
        </v-col>
      </v-row>
      <v-tabs-items v-model="tab" :dark="$dark.get()">
        <v-tab-item>
          <order-products
            :order-list="input.products"
            @up-product="$emit('up-product', $event)"
            @down-product="$emit('down-product', $event)"
            @remove-product="$emit('remove-product', $event)"
          ></order-products>
        </v-tab-item>
        <v-tab-item eager>
          <order-client
            :input="input"
            :validation="validation"
            @get-validation="$emit('get-validation', $event)"
          ></order-client>
        </v-tab-item>
        <v-tab-item>
          <order-details :input="input"></order-details>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
import OrderProducts from "@/components/Order/OrderCreate/OrderProducts";
import OrderClient from "@/components/Order/OrderCreate/OrderClient";
import OrderDetails from "@/components/Order/OrderCreate/OrderDetails";
export default {
  components: { OrderDetails, OrderClient, OrderProducts },
  props: {
    input: {
      type: Object,
      required: true,
    },
    validation: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    productPrices() {
      let prices = this.input.products.map((x) => {
        return x.price * x.amount;
      });
      let count = 0;
      prices.forEach((elem) => {
        count += elem;
      });
      return count.toFixed(1);
    },
  },
};
</script>
<style lang="scss">
.order-options__wrapper {
  min-height: 80.5vh;
  height: max-content;
  overflow: hidden;
  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
