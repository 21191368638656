<template>
  <v-card :dark="$dark.get()" class="pa-2 pt-0 order-client__wrapper" flat>
    <v-form ref="clientInput">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="input.phoneUser"
            v-mask="'+7##########'"
            label="Телефон"
            :rules="[$validate.required, $validate.phoneMin]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="input.nameUser"
            label="Имя"
            :rules="[$validate.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="input.surnameUser"
            label="Фамилия"
            :rules="[$validate.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="input.address.addressTypeId"
            item-text="name"
            item-value="id"
            :items="ATYPES.allAddress"
            label="Тип адреса"
            :rules="[$validate.required]"
            @change="$forceUpdate()"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="input.address.street"
            label="Улица"
            :rules="[$validate.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="input.address.house"
            label="Дом"
            :rules="[$validate.required]"
          ></v-text-field>
        </v-col>
        <v-col v-if="input.address.addressTypeId !== 2" cols="6">
          <v-text-field
            v-model="input.address.block"
            label="Корпус"
          ></v-text-field>
        </v-col>
        <v-col v-if="input.address.addressTypeId !== 2" cols="6">
          <v-text-field
            v-model="input.address.level"
            label="Этаж"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="
            input.address.addressTypeId !== 2 &&
            input.address.addressTypeId !== 3
          "
          cols="6"
        >
          <v-text-field
            v-model="input.address.stairs"
            label="Подъезд"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="
            input.address.addressTypeId !== 2 &&
            input.address.addressTypeId !== 3
          "
          cols="6"
        >
          <v-text-field
            v-model="input.address.code"
            label="Домофон"
          ></v-text-field>
        </v-col>
        <v-col v-if="input.address.addressTypeId !== 2" cols="6">
          <v-text-field
            v-model="input.address.room"
            label="Квартира"
            :rules="[$validate.required]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    input: {
      type: Object,
      required: true,
    },
    validation: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      ATYPES: "Atypes/STATE",
    }),
  },
  watch: {
    validation: {
      handler() {
        let stat = this.$refs.clientInput.validate();
        this.$emit("get-validation", stat);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.order-client__wrapper {
  border-radius: 0px;
  height: 100%;
}
</style>
